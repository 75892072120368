/* eslint-disable @next/next/no-img-element */
import React, { useEffect, useState } from "react";
import Link from "next/link";
import { collection_data } from "../../data/collection_data";
import HeadLine from "../headLine";
import Image from "next/image";
import { get_all_collections } from "lib/client/aleo";
const { useAlert } = require("react-alert");
const { useRouter } = require("next/router");

import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';


async function getCollections(setPageAmount, setData, page, setLoading, alert, search) {
  setLoading(true);
  try {
    const {
      collection_amount,
      collections,
      pages
    } = await get_all_collections(page);

    setPageAmount(pages);
    setData(collections);
  } catch (e) {
    const err_str = e?.error?.message || e?.message || e + "";
    alert.error(err_str);
  }
  setLoading(false);
}

const Top_collection = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageAmount, setPageAmount] = useState(0);
  const alert = useAlert();

  const router = useRouter();

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    setPage(Number(event.selected) + 1);
  };


  useEffect(() => {
    getCollections(setPageAmount, setData, page, setLoading, alert, router?.query?.search);
  }, [page]);

  return (
    <div>
      <section className="dark:bg-jacarta-800 relative py-24">
        <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <img
            src="/images/gradient_light.jpg"
            alt="gradient"
            className="h-full w-full"
          />
        </picture>
        <div className="container">
          <div className="font-display text-jacarta-700 mb-12 text-center text-lg sm:text-3xl dark:text-white flex justify-center items-center gap-x-3">
            <HeadLine text="All collections" classes="inline" />

          </div>

          {loading && (
            <div className="flex justify-center items-center h-[21rem]">
              <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-jacarta-500">
              </div>
            </div>
          )
          }
          <div style={{
            height: "fit-content"
          }} className="grid grid-cols-1 gap-3 md:grid-cols-2 md:gap-[1.875rem] lg:grid-cols-4 h-[21rem]">
            {!loading && data.map((item) => {

              return (
                <Link href={"/collection/" + item.hex_id} key={item.hex_id}>
                  <a className="relative block" style={{ height: "fit-content" }}>
                    <div
                      className="border-jacarta-100 dark:bg-jacarta-700 rounded-2xl flex border bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent "
                    >
                      <figure className="mr-4 shrink-0">

                        {/* <img src={image} alt={title} className="rounded-2lg" /> */}
                        <Image
                          src={item.public_data.metadata.icon_url}
                          alt={item.public_data.metadata.name}
                          className="rounded-2lg"
                          height={48}
                          width={48}
                          objectFit="cover"
                        />
                        <div style={{ padding: '12px' }} className="dark:border-jacarta-600 bg-jacarta-700 absolute -left-3 top-1/2 flex h-6 w-6 -translate-y-2/4 items-center justify-center rounded-full border-2 border-white text-xs text-white">
                          {item.index + 1}
                        </div>
                        {process.env.NEXT_PUBLIC_VERIFIED_COLLECTIONS.split(',').includes(item.hex_id) && (
                          <div

                            className="dark:border-jacarta-600 bg-green absolute -left-[10px] top-[60%] flex h-6 w-6 items-center justify-center rounded-full border-2 border-white"
                            data-tippy-content="Verified Collection"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                              className="h-[.875rem] w-[.875rem] fill-white"
                            >
                              <path fill="none" d="M0 0h24v24H0z"></path>
                              <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                            </svg>
                          </div>
                        )}
                      </figure>
                      <div>
                        <Link href={"/collection/" + item.hex_id}>
                          <a className="block">
                            <span className="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white">
                              {item.public_data.metadata.name}
                            </span>
                          </a>
                        </Link>
                        <span className="dark:text-jacarta-300 text-sm">
                          {item.public_data.metadata.ticker}
                        </span>
                      </div>
                    </div>
                  </a>
                </Link>
              );
            })}

          </div>
          <ReactPaginate
            className="flex justify-center mt-10 pagination_collec"
            breakLabel="..."
            nextLabel="Next>"
            onPageChange={handlePageClick}
            pageRangeDisplayed={4}
            pageCount={pageAmount} // pageAmount
            previousLabel="<Prev."
            renderOnZeroPageCount={null}
            activeLinkClassName="text-accent font-semibold"
            marginPagesDisplayed={2}
          />
          {/*

          <div className="mt-10 text-center">
            <Link href="/rankings">
              <a className="bg-accent shadow-accent-volume hover:bg-accent-dark inline-block rounded-full py-3 px-8 text-center font-semibold text-white transition-all">
                Go to Rankings
              </a>
            </Link>
          </div>*/}
        </div>
      </section>
    </div>
  );
};

export default Top_collection;
