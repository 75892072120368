import React from 'react';

const Price_history = ({ classes }) => {
	return (
		<div className="relative mb-24 w-full">
			{/* <!-- Price History --> */}
			<div className="tab-pane fade">
				<div className={classes}>
					{/* <!-- Period / Stats --> */}
					<div className="mb-10 flex flex-wrap items-center">
						<select className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 mr-8 min-w-[12rem] rounded-lg py-3.5 text-sm dark:text-white">
							<option defaultValue="7-days">Last 7 Days</option>
							<option defaultValue="14-days">Last 14 Days</option>
							<option defaultValue="30-days">Last 30 Days</option>
							<option defaultValue="60-days">Last 60 Days</option>
							<option defaultValue="90-days">Last 90 Days</option>
							<option value="last-year">Last Year</option>
							<option value="all-time">All Time</option>
						</select>

						<div className="py-2">
							<span className="mr-4 inline-block align-middle">
								<span className="block text-sm font-bold dark:text-white">90 Day Avg. Price:</span>
								<span className="text-green block text-sm font-bold">-</span>
							</span>

							<span className="inline-block align-middle">
								<span className="block text-sm font-bold dark:text-white">90 Day Volume:</span>
								<span className="text-green block text-sm font-bold">-</span>
							</span>



						</div>
						<span className="inline-block align-right" style={{ position: "absolute", right: "30px" }}>
							<h1 className="text-2xl font-bold text-jacarta-400">
								Incoming Feature
							</h1>
						</span>
					</div>

					{/* <!-- Chart --> */}
					<div className="chart-container relative h-80 w-full">
					</div>
				</div>
			</div>
		</div>
	);
};

export default Price_history;
