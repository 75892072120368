import Link from "next/link";

const hero = () => {
    return (
        <section className="relative pb-10 pt-20 md:pt-32 h-1527">
            <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 block dark:hidden h-full">
                <img
                    src="/images/gradient.jpg"
                    alt="gradient"
                    className="h-full w-full"
                />
            </picture>
            <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 hidden dark:block">
                <img
                    src="/images/gradient_dark.jpg"
                    alt="gradient dark"
                    className="h-full w-full"
                />
            </picture>

            <div className="container h-full mx-auto">
                <div className="grid h-full items-center gap-4 md:grid-cols-12">
                    <div className="col-span-6 flex h-full flex-col items-center justify-center py-10 md:items-start md:py-20 xl:col-span-4">
                        <h1 className="text-jacarta-700 font-bold font-display mb-6 text-center text-5xl dark:text-white md:text-left lg:text-6xl xl:text-6xl">
                            Incoming...
                            <br />
                        </h1>
                        <p className="dark:text-jacarta-200 mb-8 text-center text-lg md:text-left" style={{ borderBottom: "1px solid #a3a3a3" }}>
                            The first NFT marketplace on Aleo:<br />
                            Create, buy, and sell NFTs <b>anonymously.</b>
                            <br /><br />
                        </p>
                        <p className="dark:text-jacarta-200 mb-8 text-center text-lg md:text-left">
                            Meanwhile, we are developing <b>Aleo DCP</b>,<br /> a <b>protocol</b> allowing Aleo programs to hold <b>private data</b>.
                        </p>
                        <div className="flex space-x-4">
                            <Link href="https://github.com/bandersnatch-io/aleo-dcp">
                                <a className="bg-accent shadow-accent-volume hover:bg-accent-dark w-40 rounded-full py-3 px-8 text-center font-semibold text-white transition-all">
                                    Learn more
                                </a>
                            </Link>

                        </div>
                    </div>

                    {/* <!-- Hero image --> */}
                    <div className="col-span-6 xl:col-span-8">
                        <div className="relative text-center md:pl-8 md:text-right">
                            <img
                                src="/images/hero/hero.png"
                                alt=""
                                className="hero-img mt-8 inline-block w-72 sm:w-full lg:w-[24rem] xl:w-[35rem]"
                            />

                            <img
                                src="/images/hero/3D_elements.png"
                                alt=""
                                className="animate-fly absolute top-0 md:-right-[10%]"
                            />
                        </div>


                    </div>


                    <div style={{ height: "150px" }}>
                    </div>


                </div>
            </div>
        </section >
    );
};

export default hero;
